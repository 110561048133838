import React from "react"
import KontoReferanseLink from "./KontoReferanseLink"
import { areDateYear } from "./areDateYear"

const harSuffiks = (treff) => (suffiks) => {
  const tekstEtterTreff = treff.input.substr(treff.index + treff[0].length)
  return new RegExp(`^${suffiks}`).test(tekstEtterTreff)
}

const harPrefiks = (treff) => (prefiks) => {
  const finnSluttposisjon = (t) => t.index + t[0].length

  const slutt = finnSluttposisjon(treff)
  const regexpMedPrefiks = new RegExp(`${prefiks}${treff[0]}`)
  let treffMedPrefiks
  while ((treffMedPrefiks = regexpMedPrefiks.exec(treff.input))) {
    if (finnSluttposisjon(treffMedPrefiks) === slutt) {
      return true
    }
  }
  return false
}

const falskTreff = (treff) => {
  return ["RF-", "kr\\s*", "\\dx"].some(harPrefiks(treff)) || ["\\s*km", "\\s*kr"].some(harSuffiks(treff))
}

const findKonto = (kontonumre, prefix) => {
  return kontonumre.find(({ nummer }) => {
    const str = nummer.toString()
    if (str.length <= prefix.length) {
      return prefix.startsWith(str)
    } else {
      return str.startsWith(prefix)
    }
  })
}

export const kontoReferanser = (kontonumre, tekst) => {
  const nodes = [tekst]

  const kontoNummerPattern = /(?:\b|\s|^)((\d{4})|(\d{1,3}x{2,3}))(?=\D|\b)/gi
  let match
  let key = 0
  while ((match = kontoNummerPattern.exec(tekst))) {
    if (falskTreff(match)) {
      continue
    }
    const visning = match[1]

    if (tekst.indexOf(`konto ${visning}`) === -1 && areDateYear(+visning)) {
      continue
    }

    const prefiks = visning.replace(/x+$/gi, "")

    const konto = findKonto(kontonumre, prefiks)
    if (konto !== undefined) {
      const string = nodes.pop()
      const start = string.indexOf(visning)
      nodes.push(string.slice(0, start))

      nodes.push(
        <KontoReferanseLink kontoer={konto.kontoer} kontoPrefix={+prefiks} key={key}>
          {visning}
        </KontoReferanseLink>,
      )

      key++
      nodes.push(string.slice(start + visning.length))
    }
  }

  return nodes
}

import React from "react"
import { createRoot } from "react-dom/client"

import "@fiken/fonter/src/fonter.css"
import "@fiken/components/lib/esm/components.css"

import App from "./app/App"

const container = document.getElementById("root")
if (container != null) {
  const root = createRoot(container)
  root.render(<App />)
}

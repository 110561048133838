import Hero from "../hero/Hero"
import Info from "../info/Info"
import React from "react"
import { BaseViewProp } from "../../types/type"
import { useAppSelector } from "../../app/hooks"
import { FikenPromoCurvedContainer, Footer, Header, LinearProgressIndicator, ScrollToTop } from "@fiken/components"
import { setting } from "../../app/setting"
import { QueryStatus } from "@reduxjs/toolkit/query"
import { useAxeCore } from "./useAxeCore"

const FikenPromoContainer = (): React.ReactElement => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth)
  if (!isAuthenticated) {
    return (
      <div style={{ background: "var(--fiken-color-blue-300)" }}>
        <FikenPromoCurvedContainer background={"var(--fiken-color-white-500)"} />
      </div>
    )
  }
  return <></>
}

export default function Layout({ children }: BaseViewProp): React.ReactElement {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth)
  const { viewportWidth } = useAppSelector((state) => state.setting)
  const isDesktop = viewportWidth > setting.breakpoints.large
  const visLogoMedNavn = viewportWidth > setting.breakpoints.xSmall

  const isLoading = useAppSelector((state) => {
    return Object.values(state.api.queries).some((query) => {
      return query != null && query.status === QueryStatus.pending
    })
  })

  useAxeCore(isLoading)

  return (
    <>
      <LinearProgressIndicator isLoading={isLoading} />
      <Header visLogoMedNavn={visLogoMedNavn} visFikenMoto={isDesktop} isAuthenticated={isAuthenticated} />
      <main>
        <Hero />
        <div
          style={{
            minHeight: "700px",
            background: "var(--fiken-color-blue-300)",
          }}
        >
          {children}
        </div>
        <Info />
        <FikenPromoContainer />
      </main>
      <Footer />
      <ScrollToTop />
    </>
  )
}

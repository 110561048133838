import { getItem, setItem } from "../../utils/localStorage"
import { Params } from "react-router"

export type UrlParams = {
  orgForm: string
  medMoms: string
}

export const getParams = (): UrlParams | null => getItem("params") as UrlParams
export const storeParams = (params: Params): void => setItem("params", params)

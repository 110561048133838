import React, { useEffect } from "react"
import ReactDOM from "react-dom"

const { DEV } = import.meta.env

export const useAxeCore = (isLoading: boolean) => {
  useEffect(() => {
    if (!isLoading && DEV) {
      setTimeout(() => {
        const ax = import("@axe-core/react")
        ax.then((Module) => {
          const axCore = Module.default
          axCore(React, ReactDOM, 1000).then(() => {
            console.info("Run accessibility check")
          })
        })
      }, 1000)
    }
  }, [isLoading])
}
